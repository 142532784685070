// import { query } from '../store/query.module'
import store from '../store'

export class TextOverlay extends google.maps.OverlayView {

    constructor(boundaries, map, colors, boundaryTypes, showSA1Names) {
        super()
        this.divs = {}
        this.coords = {}
        this.colors = colors
        this.boundaries = boundaries
        this.boundaryTypes = boundaryTypes
        this.setMap(map)
        this.drawn = false
        this.showSA1Names = showSA1Names
    }

    clear() {
        Object.values(this.divs).forEach(div => div.remove())
        this.divs = {}
        this.coords = {}
    }

    getDiv(boundary_id) {
        if (!Object.hasOwn(this.divs, boundary_id)) {
            let boundary = this.boundaries[boundary_id]
            let div = document.createElement("div");
            div.style.borderStyle = "none";
            div.style.borderWidth = "0px";
            div.style.position = "absolute";
            div.innerHTML = this.getDivContent(boundary)
            // There's an odd intermittent error with getPanes, that seems to relate to load-order.
            // My working theory is that 'getPanes' can't be called before the map calls our 'draw' event (I think the
            // map adds the function to the Overlay, which is totally sensible and not stupid design at all.)
            this.getPanes().markerLayer.appendChild(div);
            this.divs[boundary_id] = div
        }
        return this.divs[boundary_id]
    }

    getCenter (boundary_id) {
        if (!Object.hasOwn(this.coords, boundary_id)) {
            let bbox = this.boundaries[boundary_id].bounding_box
            let coords = {
                lat: bbox.min_lat + ((bbox.max_lat - bbox.min_lat) / 2),
                lng: bbox.min_lon + ((bbox.max_lon - bbox.min_lon) / 2)
            }
            this.coords[boundary_id] = coords
        }

        return this.coords[boundary_id]
    }

    setBoundaryTypes (newBoundaryTypes) {
        this.boundaryTypes = newBoundaryTypes
        this.updateBoundaryNames()
    }

    updateBoundaryNames () {
        if (!this.drawn) {
            // We can't run this code yet, 'getPanes' fails before the map has drawn. or maybe before the overlay 'draw'
            // function is called by the maps for the first time?? It seems like adding the overlay to the map isn't
            // enough to make 'getPanes' work, the map does something silently before it call 'draw' for the first
            // time.)
            console.log('Skipping Boundary Draw, map has not been drawn yet.')
            return
        }
        let overlayProjection = this.getProjection()
        // Retrieve the south-west and north-east coordinates of this overlay
        // in LatLngs and convert them to pixel coordinates.
        // We'll use these coordinates to resize the div.

        Object.keys(this.boundaries).forEach(boundary_id => {
            let boundary = this.boundaries[boundary_id]
            let div = this.getDiv(boundary_id)
            let coords = this.getCenter(boundary_id)
            let position = overlayProjection.fromLatLngToDivPixel(coords)

            // TODO - Remove Boundaries which are a long way off screen
            if (Math.abs(position.x ) > 4000 || Math.abs(position.y) > 4000) {
                delete this.boundaries[boundary_id]
            }
            div.innerHTML = this.getDivContent(boundary)
            div.style.display = this.boundaryTypes.includes(boundary.classification) ? 'flex' : 'none'
            div.style.left =  position.x + "px"
            div.style.top = position.y + "px"
            div.style.width = 'auto'
            div.style.height = '20px'
            div.style.transform = 'translate(-50%, -50%)'
            div.style['font-size'] = '1.6em'
            div.style['font-weight'] = '600'
            // div.style.display = 'flex'
            div.style['flex-direction'] = 'column'
            div.style['justify-content'] = 'center'
            div.style['align-items'] = 'center'
            div.style.color = 'white'
            // div.style['mix-blend-mode'] = 'difference'
            // div.style.background = `rgba(${color[0]}, ${color[1]}, ${color[2]})`
            div.style.opacity = this.getVisbility(boundary)
            div.style['z-index'] = 30
            div.style['text-shadow'] = '3px 2px 2px rgba(0, 0, 0, 1)'
            // div.style.filter = 'invert(1)'
            // div.style.color = 'white'
            // div.style.color = `rgba(${color[0] + 30}, ${color[1] + 30}, ${color[2] + 30})`
            div.background = 'rgba(0,0,0,1)'
            div.style.transition = 'opacity 0.25s 0.5s ease-in-out'
        })
    }

    showLabel(boundary_id) {
        let labelDiv = this.getDiv(boundary_id)
        labelDiv.style.transition = 'opacity 0.25s ease-in-out'
        labelDiv.style.opacity = 1
    }

    resetLabel(boundary_id) {
        let labelDiv = this.getDiv(boundary_id)
        let boundary = this.boundaries[boundary_id]
        labelDiv.style.transition = 'opacity 0.5s ease-in-out'
        labelDiv.style.opacity = this.getVisbility(boundary)
    }

    getVisbility(boundary) {
        return (!this.showSA1Names && boundary.classification !== 'SA1') || this.showSA1Names ? 1 : 0
    }

    // Called when the map redraws
    draw() {
        this.drawn = true
        if (this.map) {
            this.updateBoundaryNames()
        } else {
            console.log('No map yet. Skipping Boundary Names Update')
        }
    }
    // onRemove() {
    //     console.log('TODO - Remove code!')
    // }
    //
    // getBoundaryDiv(boundary_id) {
    //     if (Object.hasOwn(this.boundaries, boundary_id)) {
    //         return this.boundaries[boundary_id]
    //     }
    // }

    getDivContent(boundary) {
        if (store.state.query.selected_dataset && store.state.query.selected_dataset_date) {
          return `<div>${boundary.name}</div> <div>(${this.getDatasetValue(boundary)})</div>`
        } else {
            return boundary.name
        }
    }

    getDatasetValue(boundary) {
        return store.getters["query/getSelectedDatasetValue"](boundary)
    }
}
